.Campuses {
    margin: auto;
    box-sizing: border-box;
    padding: 10px;
    background-color: #f6f8f9;
    & a {
        display: block;
    }
}

.Container {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 45px;
    width: 90%;
    & div {}
}

.Flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h2 {
        font-size: 2em;
    }
    & a {
        text-decoration: none;
        & button {
            outline: none;
            cursor: pointer;
            padding: 10px 20px;
            border: 1px solid black;
            border-radius: 50px;
            background-color: transparent;
            line-height: 100%;
            color: black;
            font-size: 40%;
            font-weight: bold;
            transition: background-color 0.2s, color 0.2s, border 0.2s;
            &:hover,
            &:active {
                background-color: rgba(9, 102, 214, 0.6);
                border: 1px solid rgba(9, 102, 214, 0.02);
            }
        }
    }
}

.ContainerFlex {
    display: flex;
    // flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Campus {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
    width: 200px;
    & a {
        text-decoration: none;
        margin-top: 30px;
        color: inherit;
        transition: font-weight 0.2s, color 0.2s;
        &:hover,
        &:active {
            color: #0966d6;
        }
    }
}

@media only screen and (max-width: 900px) {
    .Flexbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        & h2 {
            font-size: 1.8em;
        }
        & a button h2 {
            font-size: 2em;
        }
    }
}

@media only screen and (max-width: 350px) {
    .Flexbox {
        & h2 {
            font-size: 1.4em;
        }
        & a button {
            padding: 5px 15px;
            & h2 {
                font-size: 2em;
            }
        }
    }
    .Campus {
        font-size: 0.8em;
        & a {
            margin-top: 5px;
            font-size: 1.3em;
        }
    }
}