.Statistics {
    // overflow: hidden;
    overflow: auto;
    display: block;
    position: relative;
    margin: auto;
    box-sizing: border-box;
    padding: 10px;
    min-height: 80vh;
    width: 100%;
    text-align: center;
}

.Container {
    clear: both;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    text-align: center;
    // word-wrap: break-word;
    & h2 {
        margin-bottom: 30px;
        font-size: 125%;
        &::after {
            display: block;
            height: 2px;
            background-color: #0966d6;
            content: ' ';
            width: 200px;
            margin: 0 auto;
            margin-top: 20px;
        }
    }
    & div p {
        font-weight: 300;
        font-size: 150%;
    }
}

.Stats {
    // height:  0%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    height: auto;
    width: 100%;
}

.Ver {
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & p {
        margin: 10px 0;
    }
    & img {
        margin-top: 0;
        height: 180px;
    }
}

@media only screen and (max-width: 340px) {
    .Container div p {
        font-weight: 300;
        font-size: 120%;
        & h2 {
            font-size: 130%;
        }
    }
}

@media only screen and (min-width: 440px) {
    .Container {
        & h2 {
            font-size: 1.6em;
        }
    }
}

@media only screen and (min-width: 610px) {
    .Container {
        & h2 {
            font-size: 1.6em;
            &::after {
                margin-top: 30px;
            }
        }
    }
}

@media only screen and (min-width: 660px) {
    .Statistics {
        height: 75vh;
    }
    .Stats {
        flex-direction: row;
    }
    .Ver img {
        height: 250px;
    }
}

@media only screen and (min-width: 830px) {
    .Container {
        // width: 900px;
        // font-size: 100%;
        & h2 {
            font-size: 2em;
        }
    }
}