.Container {
    position: relative;
    // display: flex;
    box-sizing: content-box;
    height: 40px;
    width: 100%;
}

.Buttons {
    // position: relative;
    // position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
    font: inherit;
    font-weight: bold;
    color: #032b5b;
}

.Button {
    outline: none;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid transparent;
    // border-radius: 3px;
    font: inherit;
    font-size: 100%;
    font-weight: bold;
    color: #032b5b;
    transition: font-size 0.2s, color 0.2s, border-bottom 0.2s;
    &:hover,
    &:active {
        // color: #0966d6;
        border-bottom: 3px solid #032b5b;
    }
}

.Active {
    border-bottom: 3px solid #0966d6;
    font-size: 150%;
    color: #0966d6;
    transition: font-size 1s;
    &:hover,
    &:active {
        // color: #0966d6;
        border-bottom: 3px solid #0966d6;
    }
}

// .Button:disabled {
//     color: #ccc;
//     cursor: not-allowed;
// }
// .Success {
//     color: #5c9210;
// }
// .Failed {
//     color: #eb4934;
// }