.Button {
    outline: none;
    cursor: pointer;
    margin: 10px;
    margin-top: 20px;
    padding: 15px 25px;
    font: inherit;
    font-weight: bold;
    color: white;
    // background-color: #0966d6;
    background-color: rgba(9, 102, 214, 0.8); // background-color: transparent;
    border: none;
    // border: 1px solid #032b5b;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
    & a {
        color: #fff;
        text-decoration: none;
    }
    &:link,
    &:visited {
        color: #fff;
    }
    &:hover,
    &:active {
        background-color: #032b5b;
        color: #fff;
    }
}