.Search {
    z-index: 10;
    width: 180px;
}

@media only screen and (min-width: 280px) {
    .Search {
        width: 200px;
    }
}

@media only screen and (min-width: 335px) {
    .Search {
        width: 250px;
    }
}

@media only screen and (min-width: 400px) {
    .Search {
        width: 300px;
    }
}

@media only screen and (min-width: 450px) {
    .Search {
        width: 350px;
    }
}

@media only screen and (min-width: 530px) {
    .Search {
        width: 400px;
    }
}

@media only screen and (min-width: 600px) {
    .Search {
        width: 400px;
    }
}

@media only screen and (min-width: 1000px) {
    .Search {
        width: 500px;
    }
}