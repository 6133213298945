.Stars {
    width: 146.12px;
    unicode-bidi: bidi-override;
    position: relative;
    margin: 0;
    padding: 0;
    color: #7e7e7e;
    font-size: 120%;
    overflow: hidden;
    & div {
        & span {
            display: inline-block;
        }
    }
    .Fill {
        padding: 0;
        z-index: 5;
        position: absolute;
        display: inline;
        top: 0;
        left: 0;
        & div {
            display: inline-block;
            overflow: hidden;
            & span {
                display: inline;
                color: #fdcc0d;
            }            
        }

    }
    .Empty {
        padding: 0;
        display: block;
        z-index: 0;
    }
}

@media only screen and (max-width: 400px) {
    .Stars {
        font-size: 100%;
    }
}