.Yay {
    position: relative;
    overflow: hidden;
    height: 80vh;
    .Container {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 5;
    }
}