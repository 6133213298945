.Button {
    margin: auto;
    padding: 10px 40px;
    font: inherit;
    font-weight: bold;
    color: black;
    background-color: transparent;
    border: 1px solid #032b5b;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #5c9210;
}

.Failed {
    color: #eb4934;
}