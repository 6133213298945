.Layout {
    margin: auto;
    width: 70%;
    // height: 50vh;
}

@media only screen and (max-width: 500px) {
    .Layout div h1 {
        margin-block-end: 0;
        font-size: 1.6em;
    }
}