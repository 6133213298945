.Review {
    box-sizing: border-box;
    margin: 20px auto;
    padding: 10px 20px;
    border-radius: 10px;
    width: 80%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    & div {
        display: flex;
        align-items: center;
        & p {
            margin-block-start: 1em;
            // margin-block-end: 0.5em;
            margin-left: 15px;
            line-height: 100%;
            font-weight: 700;
            font-size: 120%;
        }
        & svg:first-child {
            margin-left: 0;
        }
    }
    & p {
        margin-block-start: 0em;
        font-weight: 500;
    }
}

.userInfo {
    font-size: 0.9em;
    color: #7e7e7e;
}

@media only screen and (max-width: 350px) {
    .Review {
        padding: 8px 18px;
        border-radius: 10px;
        width: 90%;
        & div {
            & p {
                margin-block-start: 0.6em;
                margin-block-end: 0.6em;
                margin-left: 15px;
                line-height: 100%;
                font-weight: 700;
                font-size: 1em;
            }
            & svg:first-child {
                margin-left: 0;
            }
        }
        & p {
            margin-block-start: 0em;
            font-weight: 500;
        }
    }
}