.Layout {
    width: 150px;
    padding: 10px;
    padding-bottom: 20px;
    margin: auto;
    box-sizing: border-box;
    display: block;
}

.Button {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    padding: 10px 15px;
    font: inherit;
    font-weight: bold;
    color: black;
    background-color: transparent;
    border: 1px solid #032b5b;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    text-align: center;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #5c9210;
}

.Failed {
    color: #eb4934;
}