.Footer {
    justify-content: space-between;
    box-sizing: border-box;
    height: 400px;
    width: 100%;
    background-color: #032b5b;
    color: #fff;
}

.Container {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    margin: 0 auto;
    padding-top: 60px;
    width: 90%;
    & ul {
        // flex-basis: 50%;
        list-style: none;
        width: 50%;
        & .title {
            margin-bottom: 30px;
            font-size: 120%;
            font-weight: bold;
            &:hover,
            &:active {
                border-bottom: none;
            }
        }
        & li {
            margin-bottom: 25px;
            & a {
                text-decoration: none;
                color: white;
                font-weight: 500;
                padding-bottom: 4px;
                border-bottom: 2px solid transparent;
                // transition: border-bottom 0.2s;
                &:hover,
                &:active {
                    border-bottom: 2px solid white;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .Footer {
        height: 600px;
    }
    .Container {
        flex-wrap: wrap;
        & ul {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .Container {
        width: 90%;
    }
}