.Logo {
    // background-color: white;
    padding: 14px;
    height: 100%;
    box-sizing: border-box;
    // border-radius: 5px;
    // & a p {
    //     text-decoration: none;
    //     line-height: 60px;
    // }
}

.Logo img {
    height: 100%;
}

@media only screen and (min-width: 600px) {
    .Logo {
        width: 80
    }
}