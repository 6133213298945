.Container {
    margin: auto;
    margin-bottom: 40px;
    // width: 90%;
}

.Layout {
    margin: auto;
    margin-top: 20px;
    width: 80%;
}

@media only screen and (max-width: 400px) {
    .Container {
        & h1 {
            font-size: 1.8em;
        }
    }
}