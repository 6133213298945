.Campuses {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;
    text-align: center;
    background-color: #f6f8f9;
    & a {
        display: block;
        width: inherit;
        text-decoration: none;
        color: inherit;
        transition: color 0.2s;
        &:active,
        &:hover {
            color: #0966d6;
        }
    }
}

.Split {
    background-color: #f6f8f9;
    min-height: 90vh;
    width: 50%;
    & h1 {
        margin-top: 50px;
    }
}

.Left {
    background-color: #f6f8f9;
    text-align: center;
    position: relative;
}

.Right {
    background-color: #f6f8f9;
    right: 0;
    position: absolute;
    min-height: 90vh;
    width: 50%;
}

// Pagination
.pagination {
    margin: auto auto 5px auto;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0px;
    list-style: none;
    padding: 0;
    text-align: center;
    outline: none;
}

.homesContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    width: 100%;
}

.pagination>.active>a {
    background-color: #0966d6;
    border-color: #0966d6;
    border-radius: 50px;
    color: #fff;
}

.pagination>li>a {
    width: 18px;
    height: 18px;
    margin: auto 5px;
    padding: 10px 10px;
    outline: none;
    cursor: pointer;
    font-weight: 700;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #0966d6;
    border-color: #0966d6;
    outline: none;
}

.pagination>li>a,
.pagination>li>span {
    color: black;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
}

@media only screen and (max-width: 400px) {
    .Right {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .Campuses {
        flex-direction: column;
    }
    .Split {
        width: 100%;
    }
    .Right {
        position: relative;
    }
}

@media only screen and (max-width: 440px) {
    .Campuses {
        flex-direction: column;
    }
    .Split {
        width: 100%;
    }
    .Right {
        display: none;
    }
}