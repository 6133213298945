.Toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 60px;
    padding: 0 5px;
    color: #fff;
    background-color: #032b5b;
    & nav {
        height: 100%;
        & ul {
            list-style: none;
            margin: 0;
            padding: 0;
            & li {
                display: inline-block;
                margin: 20px;
                & a {
                    text-decoration: none;
                    color: #fff;
                    &:hover,
                    &:active,
                    &.active {
                        border-bottom: 1px solid #fff;
                        padding-bottom: 4px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 335px) {
    .Toolbar {
        padding: 0 10px;
    }
}

@media only screen and (min-width: 400px) {
    .Toolbar {
        padding: 0 30px;
    }
}