.Overview {
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    height: 80vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../assets/img/overview.png);
    background-size: cover;
    background-position: center bottom;
    background-attachment: fixed;
}

.Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;
}

.H1 {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 15px;
    color: #fff;
    font-size: 100%;
    word-spacing: 3px;
}

@media only screen and (min-width: 350px) {
    .H1 {
        font-size: 160%;
    }
}