.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 300px;
    width: 100%;
    background-color: #f6f8f9;
    // border: 5px solid green;
}

.Ratings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    width: 50%;
    & h2 {
        margin-block-end: 0.5em;
    }
    & div {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        & h3 {
            margin-block-end: 0.5em;
            margin-right: 30px;
            &:first-child {
                margin-block-start: 0.6em;
            }
        }
        & h4 {
            margin-block-start: 0.5em;
            margin-block-end: 0.5em;
            margin-left: 20px;
            font-weight: normal;
            // color: #7e7e7e;
        }
    }
    & h5 {
        color: #7e7e7e;
    }
}

.Image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 50%;
    position: relative;
    & img {
        object-fit: cover;
        object-position: 50% 15%;
        height: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .Header {
        flex-direction: column;
        height: 600px;
    }
    .Image {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .Ratings {
        width: 90%;
    }
}

@media only screen and (max-width: 320px) {
    .Ratings div h3 {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 300px) {
    .Header {
        height: 400px;
    }
    .Ratings div {
        & h3 {
            margin-right: 10px;
        }
        & h4 {
            margin-left: 15px;
        }
    }
}