.TextArea {
    width: 100%;
    height: 120px;
    display: block;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
}

.Label {
    padding-bottom: 5px;
}

.Input {
    width: 100%;
    padding: 10px;
    margin: auto;
    box-sizing: border-box;
    display: block;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputEl {
    width: 100%;
    display: block;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
}

.InputEl:focus {
    outline: none;
    // background-color: #032b5b
}

.Invalid {
    border: 1px solid red;
    background-color: salmon;
}