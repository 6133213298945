.Control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    margin: auto;
    padding: 30px 0;
    height: 150px;
    width: 80%;
}

.Left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & a {
        text-decoration: none;
        cursor: pointer;
        border: none;
        color: #7e7e7e;
        background-color: transparent;
        font: inherit;
        font-weight: bold;
        transition: color 0.2s;
        &:hover,
        &:active {
            color: black;
        }
    }
}

.Review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & button {
        outline: none;
        cursor: pointer;
        padding: 15px 20px;
        border: none;
        border-radius: 20px;
        color: white;
        background-color: #0966d6;
        font: inherit;
        font-size: 90%;
        font-weight: bold;
        transition: background-color 0.2s, color 0.2s;
        &:hover,
        &:active {
            background-color: #032b5b;
        }
    }
}

@media only screen and (max-width: 600px) {
    .Review {
        & button {
            padding: 10px 15px;
            font-size: 80%;
        }
    }
}

@media only screen and (max-width: 500px) {
    .Control {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        height: 190px;
    }
}

@media only screen and (max-width: 300px) {
    .Left h1 {
        margin-block-end: 0.5em;
        font-size: 1.5em;
    }
}